@font-face {
font-family: '__helveticaNowDisplay_8acbb9';
src: url(/_next/static/media/59ed1cbb7a1a4602-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__helveticaNowDisplay_8acbb9';
src: url(/_next/static/media/3fe67534a1360f7b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: bold;
}@font-face {font-family: '__helveticaNowDisplay_Fallback_8acbb9';src: local("Arial");ascent-override: 113.96%;descent-override: 36.96%;line-gap-override: 0.00%;size-adjust: 97.40%
}.__className_8acbb9 {font-family: '__helveticaNowDisplay_8acbb9', '__helveticaNowDisplay_Fallback_8acbb9'
}.__variable_8acbb9 {--hb-font-family-primary: '__helveticaNowDisplay_8acbb9', '__helveticaNowDisplay_Fallback_8acbb9'
}

@font-face {
font-family: '__helveticaNowDisplayExtended_3ea65e';
src: url(/_next/static/media/3361e2f69d7fccb8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: bold;
}@font-face {font-family: '__helveticaNowDisplayExtended_Fallback_3ea65e';src: local("Arial");ascent-override: 91.10%;descent-override: 30.37%;line-gap-override: 24.29%;size-adjust: 82.33%
}.__className_3ea65e {font-family: '__helveticaNowDisplayExtended_3ea65e', '__helveticaNowDisplayExtended_Fallback_3ea65e';font-weight: 700;font-style: bold
}.__variable_3ea65e {--hb-font-family-secondary: '__helveticaNowDisplayExtended_3ea65e', '__helveticaNowDisplayExtended_Fallback_3ea65e'
}

